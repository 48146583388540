article {
  grid-area: 1 / 1 / -1 / -1;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'articleheader' 'articlebody';

  > iframe {
    grid-area: articlebody;
  }

  .location-indication {
    color: gray;
    font-style: italic;
    grid-area: articleheader;
  }
}

.editable-content {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'head'
    'body';

  .editable-content-head {
    grid-area: head;
  }

  .editable-content-body {
    grid-area: body;
  }
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 5px 5px;
  border-radius: 35px;
  font-size: 12px;
  line-height: 1.33;
  z-index: 6;
  position: absolute;
  right: 25px;
  bottom: 35px;
  font-style: inherit;
  box-shadow: 2px 5px 3px #888888;
  font-weight: bolder;
}

.page-with-sidebar {
  .collapse-button {
    display: none;
  }
}

// Responsive style
@media only screen and (max-width: 992px) {
  .page-with-sidebar {
    position: relative;
    overflow: hidden;

    .sidebar {
      position: absolute;
      height: 100%;
      transition: left 0.2s ease;
      left: 0;
    }

    .collapse-button {
      margin: 0;
      display: block;
      position: absolute;
      background: lightgrey;
      border: none;
      border-radius: 0 0.8rem 0.8rem 0;
      font-size: 1.6rem;
      padding: 15px 10px;
      top: 1rem;
      left: 313px;
      transition: left 0.2s ease;
      z-index: 1;
    }

    .closed {
      left: -313px;
    }

    .closed ~ .collapse-button {
      left: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .page-with-sidebar {
    aside {
      height: calc(100% - 105px);
    }
  }
}

@media only screen and (max-width: 576px) {
  .page-with-sidebar {
    aside {
      height: calc(100% - 106px);
    }
  }
}
