.category-navigation {
  padding-top: 0.5rem;

  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  a:active {
    text-decoration: none;
  }

  .nav-article {
    min-height: 3rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
    word-break: break-word;
  }

  .nav-article.article-active {
    background: var(--color-primary-light);
  }

  .nav-article:hover:not(.article-active) {
    background: white;
  }

  .Collapsible .Collapsible__contentInner {
    min-height: 2rem;
  }

  .Collapsible h6 {
    margin-top: 0.5em;
  }
}