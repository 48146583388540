ul.settings-sidebar {
  list-style: none;
  padding: 0;

  a {
    min-height: 3rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
    word-break: break-all;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  a.active {
    background: var(--color-primary-light);
  }
}
