.loading-page{
    grid-area: 1 / 1 / -1 / -1;
    display: grid;
    grid-template-areas: ". . ."
                         ". c ."
                         ". . .";
    .loading-inner-stackvert {

        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color:rgb(153, 153, 153);
        grid-area: c;
    }
}
.loading-spinner {
    animation: loadSpin 1.3s linear infinite;
}
@keyframes loadSpin {
    from {
         transform: rotate(-360deg);
    }
    to {
         transform: rotate(0deg);
    }
}