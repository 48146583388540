#root {
  height: 100%;
  overflow: auto;
  display: grid;

  > * {
    grid-area: 1 / 1 / -1 / -1;
  }
}

* {
  box-sizing: border-box;
}

.padded-container {
  padding: 1em 1em 1em 1em;
}

.centered-content {
  display: flex;
  justify-items: center;
}

button {
  margin: 0.2em;
}

:root {
  --grayscale-0: white;
  --grayscale-1: #e8eaf3;
  --grayscale-2: #6c757d;
  --grayscale-3: #494949;
  --grayscale-4: black;
  --color-primary: red;
  --color-accent: #f9c532;
  --color-primary-dark: red;
  --color-primary-light: #f9c532;
}

//Responsive style
@media only screen and (max-width: 992px) {
  .padded-container {
    padding: 1em 2.5em !important;
  }
}
