.form-label {
  font-weight: bold;
  margin-top: 1rem;
}

.settings-page {
  .category-locker {
    width: 2.6rem;
    height: 2.6rem;

    [data-icon='check'] {
      transform: scale(1.1) translateY(1px);
    }
    [data-icon='spinner'] {
      animation: spin infinite 2s linear;
    }
  }

  @keyframes spin {
    from {
      transform: translateY(1px) rotate(0deg);
    }
    to {
      transform: translateY(1px) rotate(360deg);
    }
  }

  h1 {
    font-weight: bold;
    color: var(--color-primary-dark);
  }
  h5 {
    margin-bottom: 1rem;
    padding-bottom: 0.3rem;
  }
  button,
  input:not(.account-select) {
    border-radius: 0.4em !important;
  }

  .btn-primary {
    background-color: var(--color-primary-dark) !important;
  }
  .row {
    --bs-gutter-x: 0rem;
  }

  .acc-mng-module {
    border: 1px solid rgba(black, 0.1);
    border-radius: 0.4rem;
    padding: 0.5rem 0.9rem;
    margin: 1rem 0;
    width: 28rem;
  }

  .categories {
    display: grid;
    grid-template-columns: 23rem 3.5rem;

    div {
      border-bottom: 1px solid lightgray;
    }
    div:last-child,
    div:nth-last-child(2) {
      border-bottom: none;
    }

    .name,
    .toggle {
      height: 3.5rem;
      display: flex;
      align-items: center;
    }

    .name {
      padding-left: 0.5rem;
      border-right: 1px solid lightgrey;
    }

    .toggle {
      justify-content: center;
      button {
        margin-top: 0.5rem;
        border-width: 1.5px;
      }
    }
  }
}
