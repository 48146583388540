header {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-top: 0.5rem;
  background: var(--color-primary);
  border-bottom: 2px solid white;

  .header-top-row {
    margin-top: 5px;
    display: flex;
    padding-bottom: 0.8rem;
    justify-content: space-between;

    .header-top-row-right {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: center;
      margin-left: 2%;

      .search-input {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        margin-right: 3px;

        .search-input-parent-stretcher.focused-child {
          width: 100%;
        }

        .search-input-parent-stretcher {
          transition: width 0.15s;
          width: 200px;
          color: var(--grayscale-1);
        }

        input:focus {
          outline: none;
        }

        input {
          border-color: var(--grayscale-1);
          color: var(--grayscale-1);
          background-color: transparent;
        }

        input::placeholder {
          color: var(--grayscale-1);
        }
      }

      .fast-search-result {
        border-bottom: 1px solid #cfcfcf;

        &:nth-child(even) {
          background-color: rgba(#f2f2f2d9, 0.85);
        }

        > svg {
          color: #888;
          padding: 9px 0 0 0;
        }
      }

      .dropdown-item.active {
        background: var(--color-primary-light);
      }
    }

    .header-top-row-left {
      h1 {
        font-size: 1.75rem;
        padding-left: 1rem;
        display: inline;
        vertical-align: middle;
        color: var(--grayscale-0);
      }

      a {
        text-decoration: none !important;
      }
    }

    > * {
      margin: 5px;
    }

    button,
    input {
      border-radius: 0.7rem;
      height: 2.2em;
      margin: 0;
      margin-left: 0.2em;
    }
  }

  .header-bottom-row {
    margin-bottom: -0.9px;

    /* chrome rendering bug */
    ol {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;

      li {
        float: left;
        min-height: 2rem;

        a {
          display: block;
          text-decoration: none;
          color: var(--color-primary-light);
          padding: 10px;

          &.active {
            font-weight: bold;
            position: relative;

            .triangle-indicator {
              fill: white;
              position: absolute;
              display: unset;
              bottom: 0;
              left: calc(50% - 7px);
            }
          }

          &::after {
            display: block;
            content: attr(title);
            font-weight: bold;
            height: 0;
            overflow: hidden;
            visibility: hidden;
          }

          .triangle-indicator {
            display: none;
          }

          &.restricted {
            text-decoration: line-through;
          }

          svg.nav-link-icon {
            margin-left: 0.25rem;
          }
        }
      }
    }
  }

  //TODO fix placeholder stuff
  .hbr-placeholder:after {
    visibility: hidden;
    content: ' ';
    margin: 10px;
  }

  .search-dropdown {
    a:link,
    a:visited,
    a:hover,
    a:active {
      text-decoration: none;
    }
  }

  .header-top-button {
    display: flex;
    transform: scaleX(1);

    transition: transform 1s ease-in;
  }

  //Responsive style
  @media only screen and (max-width: 1400px) {
    .header-bottom-row {
      ol {
        li {
          a {
            font-size: 0.9rem !important;
            padding: 10px 4px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .header-top-button {
      display: none !important;
      transform: scaleX(0);
    }

    .header-top-category-navigation {
      display: none !important;
    }

    .header-top-row {
      align-items: center !important;
    }
  }

  @media only screen and (max-width: 576px) {
    .header-top-row-right {
      display: none !important;
    }
  }
}
