.layout-wrapper {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'body'
    'footer';
  overflow: hidden;

  header {
    grid-area: header;
  }
  footer {
    grid-area: footer;
  }
  .site-body {
    grid-area: body;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    display: grid;
    overflow: auto;
  }
}
