.footer-box {
  background-color: var(--color-primary);
  font-size: 14px;
  text-align: center;
  a,
  div {
    display: inline-block;
    text-align: center;
    height: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .footer-box {
    font-size: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .footer-box {
    font-size: 8px;
    a,
    div {
      line-height: 16px;
    }
  }
}
