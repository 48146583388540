header {
  .hamburger-menu-box {
    display: none;
    margin: 0;
  }

  .icon-hamburger-menu {
    display: none;
  }

  @media only screen and (max-width: 1200px) {
    .icon-hamburger-menu {
      display: block;
    }

    .toggle-hamburger-icon {
      background: none;
      border: none;
      color: aliceblue;
      font-size: 1.5rem;
      padding: 0 10px 0 20px;
    }

    .hamburger-menu-box {
      display: flex;
      position: fixed;
      flex-direction: column;
      width: 350px;
      height: 100vh;
      background-color: var(--color-primary);
      top: 0;
      z-index: 10000;
      box-shadow: -6px 1px 10px 0px rgb(0 0 0 / 38%), 0 6px 20px 0 rgb(0 0 0 / 19%);
      transition: right ease-in 0.2s;
      overflow: hidden;

      &.visible {
        right: 0;
      }
      &.hidden {
        right: -360px;
      }

      .search-input-parent-stretcher {
        width: 100% !important;
      }

      .hamburger-menu-top {
        padding: 1rem;

        .hamburger-menu-top-icon {
          color: var(--grayscale-0);
          font-size: 1.5rem;
        }
      }

      .hamburger-menu-body {
        padding-top: 1rem;
        padding: 0.5rem;

        ol {
          li {
            float: none;
            margin: 5px 0;

            a {
              margin: 10px;
              font-size: 1rem !important;
              padding: 10px 15px;

              &.active {
                font-weight: bold;
                position: relative;

                .triangle-indicator {
                  fill: var(--color-primary-light);
                  position: absolute;
                  visibility: inherit;
                  top: 50%;
                  transform: translateY(-50%) rotate(90deg);
                  left: -5px;
                  margin: 0;
                }
              }
            }
          }
        }
      }

      .hamburger-menu-bottom {
        border-top: 2px solid rgba(black, 0.1);
        background-color: rgba(black, 0.1);
        padding: 0.5rem;
        display: grid;
        grid-template-columns: auto auto;
        gap: 0.5rem;

        button {
          width: 100%;
          height: auto;
          justify-self: end;
        }
      }
    }
  }

  @media screen and(max-width: 576px) {
    .hamburger-menu-top {
      .header-top-row-right {
        display: block !important;
      }
    }
  }
}
