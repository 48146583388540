.btn-primary {
  background-color: var(--color-primary-dark) !important;
  border-radius: 0.4rem;
}
.editable-item:hover .btn-group {
  display: flex;
}
.editable-item .btn-group {
  display: none;
  position: absolute;
  right: 0;
  top: 0.3rem;
  .btn {
    padding-left: 0;
    padding-right: 0.2rem;
  }
}
.draggable-section > * > .editable-item .btn-group svg {
  box-shadow: 0 0 5px 5px white;
  background: white;
}
.editable-item {
  min-height: 3rem;
  position: relative;
}
.article-deleted,
.section-deleted {
  opacity: 0.5;
  .article-name-truncate {
    color: red;
    &:after {
      content: ' (!)';
    }
  }
}
*[data-react-beautiful-dnd-droppable] {
  min-height: 15px;
}
.sort-warning {
  margin: 0.5rem;
}

.article-adder {
  height: 3em;
  margin: 0;
  padding-left: 1rem;
  button {
    margin: 0;
  }
}
.section-adder {
  height: 3em;
  margin: 0;
  margin-top: 1rem;
  padding-left: 1rem;
}

.article-name-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 2rem;
}
.draggable-section {
  border-bottom: 1px solid lightgrey;
}
.bottom-modal-noblock {
  .modal {
    top: unset;
    height: 0px;
    overflow: visible;
    .modal-dialog {
      max-width: 20rem;
    }
    .modal-content {
      margin-top: -170px;
    }
    .modal-body {
      padding: 0.5rem;
      text-align: center;
      .save-or-cancel {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.navable-article {
  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
  a:active {
    text-decoration: none;
  }

  &.article-active {
    background: var(--color-primary-light) !important;
  }
  &:hover:not(.article-active) {
    background: white;
  }
}

.article-adder,
.section-adder {
  button {
    font-size: 0.85rem;
    margin-top: 0.2rem;
  }
}
