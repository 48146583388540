html,
body {
  height: 100%;
  margin: 0; /*No scrollbars, TODO check if necessary*/
}
#root {
  height: 100%;
  overflow: auto;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'body';
}

* {
  box-sizing: border-box;
}

:root {
  --grayscale-0: white;
  --grayscale-1: #e8eaf3;
  --grayscale-2: #6c757d;
  --grayscale-3: #494949;
  --grayscale-4: black;
  --color-primary: red;
  --color-accent: #f9c532;
  --color-primary-dark: red;
  --color-primary-light: #f9c532;
}

a { color: $primary-def; }
a:link { text-decoration: none !important; }
a:visited { text-decoration: none !important; }
a:hover { text-decoration: none !important; color: $primary-def; }
a:active { text-decoration: none !important; }