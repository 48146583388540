.reset-pw-page {
  margin: 2em;
  justify-content: center;
  display: grid;
  grid-template-rows: auto 1fr;

  h2 {
    color: var(--color-primary);
    font-size: 3rem;
  }
  p {
    color: var(--grayscale-2);
    margin-bottom: 2rem;
    a {
      text-decoration: underline;
    }
  }
  label {
    display: block;
  }
  input {
    width: 100%;
    padding-left: 2em;
    border-radius: 0.4rem !important;
  }
  button {
    margin-top: 0.5rem !important;
  }
}
