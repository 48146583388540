.centered-page-box {
  .row {
    --bs-gutter-x: 0rem;
  }
  margin: 2em;
  justify-content: center;

  display: grid;
  grid-template-rows: auto 1fr;

  .login-form {
    width: 800px;
    padding: 3em;
    .login-header {
      margin-bottom: 2rem;
      h2 {
        color: var(--color-primary);
        font-size: 3rem;
      }
      p {
        color: var(--grayscale-2);
      }
    }
    > * {
      margin: 1em;
    }
    .login-mode-buttons {
      display: flex;
      justify-content: center;
      button {
        width: 9rem;
        border-radius: 0;
      }
    }
    .login-fields {
      label {
        display: block;
      }
      input {
        width: 100%;
        padding-left: 2em;
      }
    }
  }
}
