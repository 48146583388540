.search-page {
  grid-area: 1 / 1 / -1 / -1;
  display: grid;
  grid-template-areas:
    '. . i . .'
    '. . f . .'
    '. r r r .'
    '. r r r .'
    '. . . . .'
    '. p p p .';
  grid-template-columns: 1fr auto auto auto 1fr auto;
  grid-template-rows: auto auto 1fr 1fr;

  hr {
    margin: 0;
  }

  .sort-select {
    grid-area: f;
  }

  .search-results-list {
    max-width: 1000px;
    grid-area: r;
    margin: 1rem;

    .search-result {
      cursor: pointer;
      height: 15rem;
      position: relative;
      border: 1px solid #c0c0c0;
      margin: 15px 0;
      box-shadow: 1px 1px 6px #d8d8d8;
      transition: box-shadow 0.1s;
      border-radius: 0.4rem;

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        box-shadow: 2px 2px 6px #9f9f9f;
        opacity: 0;
        transition: opacity 0.15s ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:hover::after {
        opacity: 1;
      }

      &:last-child {
        margin-bottom: 85px;
      }

      .header {
        background: var(--grayscale-1);
        color: var(--color-primary);
        padding: 20px;
        border-bottom: 1px solid #4646463b;
        border-radius: 0.4rem 0.4rem 0 0;

        & > svg {
          font-size: 0.9rem;
          margin: 0 7px;
        }
      }

      iframe {
        height: 10rem;
      }
    }
  }

  .search-results-for {
    min-width: 800px;
    width: 1000px;
    max-width: 1000px;
    grid-area: i;
    margin: 1rem;

    h1 {
      color: var(--color-primary);
    }
  }

  .search-input-icon {
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  .pagination-container {
    overflow: hidden;
    margin-bottom: 1.8rem;
    padding-top: 1rem;
    width: 100%;
  }

  .page-picker {
    width: 100%;
    height: 100%;
    background: #ffffffde;
    box-shadow: 1px 1px 5px #4f4f4f;
  }

  .pagination-row {
    background-color: #fff;
    border-radius: 0.9rem;
    border: 1px solid #3939394a;
    overflow: hidden;
    cursor: pointer;

    input {
      width: 50px;
      border: none;
      text-align: center;
    }
  }

  .page-clicker {
    padding: 1em 1.5em;
    color: var(--bs-gray-900);
    font-size: inherit;

    &:hover {
      color: var(--color-primary-light);
      background-color: var(--color-primary);
      transition: all 0.3s ease;
    }
  }

  .current-page {
    background: var(--color-primary);
    background-color: var(--color-primary);
    color: #e8eaf3;
  }
}

.mark {
  padding: 0;
}

.modal-body article {
  min-height: 90vh;
}

.modal-lg {
  max-width: 1000px;
}
