article {
  display: flex;
  flex: 1;
  flex-direction: column;

  .article-location {
    span {
      color: gray;
      font-style: italic;
    }

    a:link,
    a:visited,
    a:hover,
    a:active {
      text-decoration: none;
    }
  }

  .article-body {
    flex: 1;
  }
}
