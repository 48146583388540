$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

// TODO: this vars should be mapped to the core.ApplicationStyle. !important

$primary-def: #6c757d;
$secondary-def: #e8eaf3;
$dark-def: #494949;
$danger-def: #dc3545 !default;

$theme-colors: map-merge($theme-colors, (
  'primary': $primary-def,
  'secondary': $secondary-def,
));

$link-decoration: none !default;
$list-group-bg: transparent;
$list-group-border-width: 0;

$border-radius: 2px !default;
$border-radius-lg: 0rem !default;
$border-radius-sm: 0rem !default;

$font-size-12: 0.75rem;
$font-size-14: 0.875rem;
$font-size-16: 1rem;
